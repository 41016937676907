import React from 'react';
import '../assets/styles/NotFound.css';

const NotFound = () => {
    return (
        <div className="NotFound">
            <h1>404 - Page Not Found</h1>
        </div>
    );
};

export default NotFound;
